import React, { useState, useRef, useEffect } from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./TollReceipt.css";

const TollReceipt = () => {
  const tollPrices = {
    "Car, Jeep, Van, LMV or Tractor with trolley car": { single: 60, return: 90 },
    "LCV, LGV or Mini Bus": { single: 90, return: 135 },
    "Bus or Truck": { single: 180, return: 270 },
    "HCM or EME or Multi Axle Vehicle (3 to 6 axle)": { single: 295, return: 445 },
    "Over Sized Vehicle (7 or more axle)": { single: 360, return: 540 },
  };

  const groupedVehicles = {
    60: ["Car", "Jeep", "Van", "LMV", "Tractor with trolley car"],
    90: ["LCV", "LGV", "Mini Bus"],
    180: ["Bus", "Truck"],
    295: ["HCM", "EME", "Multi Axle Vehicle (3 to 6 axle)"],
    360: ["Over Sized Vehicle (7 or more axle)"],
  };

  const [vehicleType, setVehicleType] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [journeyType, setJourneyType] = useState("");
  const [receipt, setReceipt] = useState(null);

  const vehicleTypeRef = useRef(null);
  const journeyTypeRef = useRef(null);
  const vehicleNumberRef = useRef(null);
  const receiptRef = useRef();

  const isFormValid = vehicleType && vehicleNumber && journeyType;

  // Set initial focus on Vehicle Type dropdown
  useEffect(() => {
    vehicleTypeRef.current?.focus();
  }, []);

  const handleGenerateReceipt = (e) => {
    e.preventDefault();
    const mappedType = Object.keys(tollPrices).find((key) =>
      groupedVehicles[Object.keys(groupedVehicles).find((price) => groupedVehicles[price].includes(vehicleType))]
    );
    const priceDetails = tollPrices[mappedType];
    const price = journeyType === "single" ? priceDetails.single : priceDetails.return;
    const ticketNumber = Math.floor(1000 + Math.random() * 9000);
    const currentTime = new Date().toLocaleString("en-US", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    setReceipt({
      vehicleType,
      vehicleNumber,
      journeyType,
      price,
      ticketNumber,
      currentTime,
    });
  };

  const handlePrintWindow = () => {
    if (receiptRef.current) {
      const printWindow = window.open("", "PRINT", "height=400,width=600");
      printWindow.document.write(`
        <html>
          <head>
            <title>Receipt</title>
            <link rel="stylesheet" href="TollReceipt.css">
            <style>
              body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
              .receipt { width: 300px; margin: auto; font-family: monospace; font-size: 12px; line-height: 1.5; }
              .receipt-header { text-align: center; font-weight: bold; font-size: 14px; margin: 5px 0; }
              .receipt-footer { text-align: center; font-size: 10px; margin: 5px 0; }
            </style>
          </head>
          <body>
            ${receiptRef.current.outerHTML}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }
  };

  return (
    <div className="receipt-container">
      <h1 className="title">Toll Receipt Generator</h1>
      <form onSubmit={handleGenerateReceipt}>
        <div className="input-group">
          <select
            ref={vehicleTypeRef}
            className="input-field"
            value={vehicleType}
            onChange={(e) => {
              setVehicleType(e.target.value);
              journeyTypeRef.current?.focus();
            }}
          >
            <option value="" disabled>Select Vehicle Type</option>
            {Object.entries(groupedVehicles).map(([price, vehicles]) => (
              <optgroup key={price} label={`₹${price}`}>
                {vehicles.map((vehicle) => (
                  <option key={vehicle} value={vehicle}>{vehicle}</option>
                ))}
              </optgroup>
            ))}
          </select>
        </div>
        <div className="input-group">
          <select
            ref={journeyTypeRef}
            className="input-field"
            value={journeyType}
            onChange={(e) => {
              setJourneyType(e.target.value);
              vehicleNumberRef.current?.focus();
            }}
          >
            <option value="" disabled>Select Journey Type</option>
            <option value="single">Single</option>
            <option value="return">Return</option>
          </select>
        </div>
        <FloatingLabel controlId="vehicleNumber" label="Enter Vehicle Number" className="mb-3">
          <Form.Control
            ref={vehicleNumberRef}
            type="text"
            value={vehicleNumber}
            onChange={(e) => setVehicleNumber(e.target.value)}
            placeholder="Enter Vehicle Number"
          />
        </FloatingLabel>
        <button
          type="submit"
          className="submit-btn"
          disabled={!isFormValid}
        >
          Generate Receipt
        </button>
      </form>

      {receipt && (
        <div style={{ textAlign: "center" }}>
          <div ref={receiptRef} className="receipt">
            <p className="receipt-header">Public Work Department ABC</p>
            <p className="receipt-header">ABC Toll Plaza</p>
            <div className="receipt-section">
              <p className="receipt-detail">Toll Plaza Name: ABC</p>
              <p className="receipt-detail">Ticket Number: {receipt.ticketNumber}</p>
              <p className="receipt-detail">Booth and Operator: L5 and B5</p>
              <p className="receipt-detail">Date and Time: {receipt.currentTime}</p>
              <p className="receipt-detail">Vehicle No.: {receipt.vehicleNumber}</p>
              <p className="receipt-detail">Type of Vehicle: {receipt.vehicleType}</p>
              <p className="receipt-detail">Type of Journey: {receipt.journeyType.charAt(0).toUpperCase() + receipt.journeyType.slice(1)}</p>
              <p className="receipt-detail">Fee: ₹{receipt.price}</p>
            </div>
            <p className="receipt-footer">Thank you</p>
            <p className="receipt-footer">Happy Journey</p>
          </div>
          <button className="print-btn" onClick={handlePrintWindow}>Print Receipt</button>
        </div>
      )}
    </div>
  );
};

export default TollReceipt;
